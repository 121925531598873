import React from "react";

const Tag = ({ text, url }) => {
  return (
    <a
      href={url}
      target="__blank"
      className="tag_hover bg-[#E8E8FF] hover:bg-purple-500 text-purple-500 hover:text-white shadow-sm px-3 lg:px-4 py-1 lg:py-1 rounded-md z-10"
    >
      <p className="text-xs font-medium tracking-wider lg:text-base ">
        {text.toUpperCase()}
      </p>
    </a>
  );
};

export default Tag;
